import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Typography, FormControlLabel, Checkbox, Link, FormGroup, Snackbar, Alert } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import dayjs from 'dayjs';
import TextInputField from '../../components/form/TextInputField';
import EmailField from '../../components/form/EmailField';
import PhoneNumberField from '../../components/form/PhoneNumberField';

const NailAppointmentReservation = forwardRef(({ selectedAppointment, price, isMobile, onReservationComplete, category }, ref) => {
    const formattedDate = selectedAppointment ? dayjs(selectedAppointment.date).format("DD.MM.YYYY HH:mm") : 'Kein Termin ausgewählt';
    
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        name: "",
        phone: "",
        email: "",
        agbAccepted: false,
        website: "", // Hinzugefügt
    });
    const [errors, setErrors] = useState({});

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const handleChange = ({ target: { name, value, type, checked } }) => {
        const newValue = type === 'checkbox' ? checked : value;
        setFormValues((prev) => ({ ...prev, [name]: newValue }));
        setErrors((prev) => ({ ...prev, [name]: '' }));
    };
    

    const handleBlur = ({ target: { name, value } }) => {
        if (!value && name !== "agbAccepted") {
          setErrors((prev) => ({ ...prev, [name]: "Pflichtfeld" }));
        }
      };

      const handleSubmit = () => {
        if (formValues.website) {
          // Bot erkannt – ignoriere das Formular
          return;
        }
        
        const newErrors = {};
        Object.keys(formValues).forEach((key) => {
            if (!formValues[key] && key !== "agbAccepted" && key !== "website") {
                newErrors[key] = "Pflichtfeld";
            }
        });

        setErrors(newErrors);
        const hasErrors = Object.keys(newErrors).length > 0;

        if (hasErrors) {
            return;
        }
    
        if (!formValues.agbAccepted) {
            setSnackbarOpen(true);
            return;
        }
    
        const updatedAppointment = {
            ...selectedAppointment,
            name: formValues.name,
            email: formValues.email,
            phone: formValues.phone,
            status: 'reserved',
            category,
        };

        onReservationComplete(updatedAppointment);
    };
    
    
    useImperativeHandle(ref, () => ({
        submitForm: () => handleSubmit(),
    }));

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid2 container direction="column" sx={{ textAlign: isMobile ? 'center' : 'left', marginTop: isMobile ? '1rem' : 0 }}>
                    <Grid2 xs={12}>
                        <Typography variant="h4">Termin reservieren</Typography>
                    </Grid2>
                    <Grid2 container justifyContent="space-between" alignItems="center" sx={{ marginBottom: '1rem' }}>
                        <Grid2>
                            <Typography variant="h6" sx={{ marginLeft: '0.2rem' }}>
                                {formattedDate}
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Typography variant="h6" sx={{ marginRight: '0.2rem' }}>
                                {price}.- CHF
                            </Typography>
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={12} sx={{ marginBottom: '1rem' }}>
                        <TextInputField
                            label="Name"
                            name="name"
                            placeholder="Vorname Name"
                            value={formValues.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.name}
                            helperText={errors.name}
                        />
                    </Grid2>
                    <Grid2 xs={12} sx={{ marginBottom: '1rem' }}>
                        <EmailField
                            value={formValues.email}
                            onChange={(email) => handleChange({ target: { name: 'email', value: email } })}
                            onBlur={() => handleBlur({ target: { name: 'email', value: formValues.email } })}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <PhoneNumberField
                            value={formValues.phone}
                            onChange={(phone) => handleChange({ target: { name: 'phone', value: phone } })}
                            onBlur={() => handleBlur({ target: { name: 'phone', value: formValues.phone } })}
                            error={!!errors.phone}
                            helperText={errors.phone}
                        />
                    </Grid2>

                    <Grid2 xs={12} sx={{ display: 'none' }}>
                        <TextInputField
                            label="Lass mich leer"
                            name="website"
                            placeholder="Lass mich leer"
                            value={formValues.website}
                            onChange={handleChange}
                        />
                    </Grid2>

                    <Grid2 container justifyContent="flex-end" sx={{ marginTop: '1rem' }}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="agbAccepted"
                                        checked={formValues.agbAccepted}
                                        onChange={handleChange}
                                        required
                                    />
                                }
                                label={
                                    <>
                                        Ich akzeptiere die{' '}
                                        <Link href="/agb" target="_blank" rel="noopener">
                                            AGB
                                        </Link>
                                    </>
                                }
                                labelPlacement="start"
                                sx={{ marginRight: 0 }}
                            />
                            {errors.agbAccepted && (
                                <Typography variant="body2" color="error" sx={{ marginLeft: '0.2rem' }}>
                                    {errors.agbAccepted}
                                </Typography>
                            )}
                        </FormGroup>
                    </Grid2>
                </Grid2>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={10000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Bitte lesen sie die AGB und akzeptieren Sie diese, um fortzufahren.
                </Alert>
              </Snackbar>
        </>
        
    );
});

export default NailAppointmentReservation;
