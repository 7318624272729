import React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';

function NailCard({ title, description, price, image, onClick }) {

    const handleClick = () => {
        onClick();
    };

    return (
        <motion.div
            whileHover={{ scale: 1.05, boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.15)" }}
            whileTap={{ scale: 0.95 }}
        >
            <Card sx={{ width: 345, borderRadius: 2, overflow: 'hidden', boxShadow: 3, backgroundColor: '#FFFFFF' }}>
                <CardActionArea onClick={handleClick}>
                    <CardMedia
                        component="img"
                        height="295"
                        image={image}
                        alt={title}
                        sx={{
                            backgroundColor: '#DCC4BC',
                            transition: 'transform 0.4s ease',
                            '&:hover': { transform: 'scale(1.05)' },
                        }}
                    />
                    <CardContent sx={{ backgroundColor: '#DCC4BC', textAlign: 'center' }}>
                        <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '1.2rem' }}>
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ height: '3em', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {description}
                        </Typography>
                        <Box
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                backgroundColor: '#FBF3EB',
                                borderRadius: 1,
                                padding: '0.5rem 1rem',
                                marginTop: '1rem',
                                display: 'inline-block',
                            }}
                        >
                            CHF {price}
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </motion.div>
    );
}

export default NailCard;
