import React from 'react';
import {
  Alert,
  AlertTitle,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import EventIcon from '@mui/icons-material/Event';
import PaymentIcon from '@mui/icons-material/Payment';
import WarningIcon from '@mui/icons-material/Warning';
import PhoneIcon from '@mui/icons-material/Phone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const NailAppointmentConfirmation = ({ price, selectedAppointment }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid2 container direction="column" spacing={2}>
      <Grid2>
        <Typography textAlign={'center'}>
          Wir freuen uns auf Ihren Besuch! In Kürze erhalten Sie eine Bestätigungs-E-Mail. Bitte überprüfen Sie auch Ihren Spam-Ordner.
        </Typography>
      </Grid2>

      <Grid2>
        <Alert
          icon={<EventIcon color="primary" />}
          severity="info"
          variant="outlined"
          sx={{
            borderColor: '#BE9F93',
            color: 'black',
          }}
        >
          <Typography variant="body2">
            Datum: <strong>{selectedAppointment?.date.format('DD.MM.YYYY')}</strong>
            <br />
            Uhrzeit: <strong>{selectedAppointment?.date.format('HH:mm')} Uhr</strong>
            <br />
            Referenznummer: <strong>{(selectedAppointment?.id).slice(-5)}</strong>
          </Typography>
        </Alert>
      </Grid2>

      <Grid2 container direction="column" spacing={1}>
        <Alert
          icon={<PaymentIcon color="primary" />}
          severity="info"
          variant="outlined"
          sx={{
            borderColor: '#BE9F93',
            color: 'black',
          }}
        >
          <AlertTitle>Zahlungsinformationen</AlertTitle>
          <Typography variant="body2" gutterBottom sx={{ textAlign: 'left' }} fontSize={'0.8rem'}>
            Bitte überweisen Sie den Betrag von <strong>{price} CHF</strong>.
            <br />
            Mindestvorauszahlung: <strong>40 CHF</strong>
          </Typography>
          <List sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? '1rem' : '4rem', width: '100%' }}>
            <ListItem sx={{ width: '100%', flex: '1' }}>
              <ListItemIcon>
                <AccountBalanceIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Banküberweisung"
                secondary={
                  <>
                    <Typography component="span" sx={{ display: 'block', fontWeight: 'bold', fontSize: '0.8rem' }}>
                      Zürcher Kantonalbank
                    </Typography>
                    <Typography component="span" sx={{ whiteSpace: 'nowrap', wordBreak: 'keep-all', fontWeight: 'bold', fontSize: '0.8rem' }}>
                      CH93 0076 2011 6238 5295 7
                    </Typography>
                    <Typography component="span" sx={{ wordBreak: 'keep-all', fontSize: '0.8rem' }}>
                      BIC (SWIFT): <strong>ZKBKCHZZ80A</strong>
                    </Typography>
                  </>
                }
                sx={{ marginLeft: isMobile ? 0 : 2 }}
                slotProps={{
                  secondary: {
                    sx: {
                      color: 'black',
                      fontSize: '0.8rem',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                    },
                  },
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PhoneIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="TWINT"
                secondary={
                  <>
                    <strong>079 123 45 67</strong>
                  </>
                }
                sx={{ marginLeft: isMobile ? 0 : 2 }}
                slotProps={{
                  secondary: {
                    sx: {
                      color: 'black',
                      fontSize: '0.8rem',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                    },
                  },
                }}
              />
            </ListItem>
          </List>
        </Alert>
      </Grid2>

      <Grid2 container direction="column" spacing={1}>
        <Grid2>
          <Alert icon={<WarningIcon />} severity="warning" variant="outlined">
            <Typography textAlign={'left'} fontSize={'0.8rem'} color='black'>
              Wenn die Zahlung nicht innerhalb von 24 Stunden erfolgt, wird die Reservierung
              automatisch storniert.
            </Typography>
          </Alert>
        </Grid2>
        <Grid2>
          <Alert icon={<WarningIcon />} severity="warning" variant="outlined">
            <Typography textAlign={'left'} fontSize={'0.8rem'} color='black'>
              Bei Stornierung der Reservierung innerhalb von 24 Stunden vor dem Termin wird die
              Mindestvorauszahlung nicht zurückerstattet.
            </Typography>
          </Alert>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default NailAppointmentConfirmation;
