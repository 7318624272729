import './App.css';
import theme from './assets/theme/theme';
import { Route, Routes, Navigate } from 'react-router-dom';
import Layout from './Layout';
import Nails from './screens/nails/Nails';
import Home from './screens/home/Home';
import Privacy from './screens/privacy/Privacy';
import Imprint from './screens/imprint/Imprint';
import Beauty from './screens/beauty/Beauty';
import Agb from './screens/agb/Agb';
import { ThemeProvider } from '@mui/material/styles';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='/beauty' element={<Beauty />} />
          <Route path='/nails' element={<Nails />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/imprint' element={<Imprint />} />
          <Route path='/agb' element={<Agb />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
