import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CardMedia,
    IconButton,
    Typography,
    useMediaQuery,
    Snackbar,
    Alert,
    CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid2 from '@mui/material/Grid2';
import dayjs from 'dayjs';
import { NailService } from '../../services/NailService';
import NailAppointmentSelector from './NailAppointmentSelector';
import NailAppointmentReservation from './NailAppointmentReservation';
import NailAppointmentConfirmation from './NailAppointmentConfirmation';

function NailDetailDialog({ open, onClose, title, description, price, image }) {
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(1);
    const [emailErrorOpen, setEmailErrorOpen] = useState(false);
    const [appointmentAlreadyBookedOpen, setAppointmentAlreadyBookedOpen] = useState(false);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const paymentRef = useRef();

    const handleDateChange = (appointment) => {
        setSelectedAppointment(appointment);
    };

    const onReserve = async () => {
        setLoading(true);
        if (paymentRef.current) {
            await paymentRef.current.submitForm();
        }
        setLoading(false);
    };

    const onReservationComplete = async (updatedAppointment) => {
        try {
            const latest = await NailService.getAppointmentById(updatedAppointment.id);
            if (latest.status !== 'free') {
                setAppointmentAlreadyBookedOpen(true);
                await fetchAppointments();
                setStep(1);
                setSelectedAppointment(null);
                return;
            }

            await NailService.updateAppointment(updatedAppointment.id, updatedAppointment);
            setStep(3);
            setLoading(false);

            const updatedAppointmentToSend = {
                ...updatedAppointment,
                price: price,
                date: updatedAppointment.date.toDate().toISOString(),
            };
            const body = JSON.stringify({ appointment: updatedAppointmentToSend });
            const response = await fetch("https://us-central1-moonnila-ee493.cloudfunctions.net/sendConfirmation", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body,
            });
        
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Server error (${response.status}): ${errorText}`);
            }

            await response.json().catch(async () => {
                const rawText = await response.text();
                console.warn("Non-JSON response from server:", rawText);
            });
        } catch (error) {
            console.error("Failed to send confirmation email", error);
            setEmailErrorOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchAppointments = useCallback(async () => {
        setLoading(true);
        try {
            const fetchedAppointments = await NailService.getAppointments();
            const appointmentsWithDayjs = fetchedAppointments.map(appointment => ({
                ...appointment,
                date: dayjs(appointment.date),
            }));
            setAppointments(appointmentsWithDayjs);
        } catch (error) {
            console.error('Error fetching appointments: ', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (open) {
            fetchAppointments();
            setStep(1);
            setSelectedAppointment(null);
        }
    }, [open, fetchAppointments]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            fullScreen={isMobile}
            sx={{ "& .MuiDialog-paper": { maxWidth: "800px" } }}
        >
            <DialogTitle sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', alignItems: isMobile || step === 3 ? 'center' : 'flex-start', marginBottom: '1rem', marginTop: '1rem' }}>
                <Typography sx={{ fontSize: '1.5rem', paddingLeft: isMobile || step === 3 ? 0 : '2rem', flexGrow: 1, textAlign: isMobile || step === 3 ? 'center' : 'left' }}>
                    {step === 3 ? 'Vielen Dank für Ihre Reservierung!' : title}
                </Typography>
                {step !== 3 && (
                    <>
                        <Typography sx={{ paddingLeft: '2rem', paddingRight: '2rem', textAlign: isMobile ? 'center' : 'left', fontSize: '0.95rem', color: 'text.secondary' }}>
                            {description}
                        </Typography>
                        <Typography sx={{ paddingLeft: isMobile ? 0 : '2rem', textAlign: isMobile ? 'center' : 'left', fontWeight: 500 }}>
                            CHF {price}
                        </Typography>
                    </>
                )}
                <IconButton onClick={onClose} sx={{ position: 'absolute', right: '1rem', top: '1rem' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ gap: '3rem', padding: '1.5rem', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'center' : 'stretch' }}>
                {step !== 3 && (
                    <Grid2 xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center' }}>
                        <CardMedia
                            component="img"
                            image={image}
                            alt={title}
                            sx={{
                                borderRadius: 2,
                                width: '100%',
                                maxWidth: '300px',
                                aspectRatio: '1 / 1',
                                objectFit: 'cover',
                            }}
                        />
                    </Grid2>
                )}

                {step === 1 && (
                    <NailAppointmentSelector
                        description={description}
                        price={price}
                        appointments={appointments}
                        selectedAppointment={selectedAppointment}
                        onDateChange={handleDateChange}
                        loading={loading}
                        isMobile={isMobile}
                    />
                )}
                {step === 2 && (
                    <NailAppointmentReservation
                        ref={paymentRef}
                        selectedAppointment={selectedAppointment}
                        price={price}
                        isMobile={isMobile}
                        onReservationComplete={onReservationComplete}
                        category={title}
                    />
                )}
                {step === 3 && (
                    <NailAppointmentConfirmation
                        price={price}
                        selectedAppointment={selectedAppointment}
                    />
                )}
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'flex-end', padding: '1rem 1.5rem' }}>
                {step === 2 && (
                    <Button
                        onClick={() => setStep(1)}
                        variant="contained"
                        color="primary"
                    >
                        Zurück
                    </Button>
                )}
                <Button
                    onClick={() => {
                        if (step === 1) {
                            setStep(2);
                        } else if (step === 2) {
                            onReserve();
                        } else if (step === 3) {
                            onClose();
                        }
                    }}
                    disabled={step === 1 && !selectedAppointment}
                    variant="contained"
                    color="primary"
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : (step === 1 ? 'Weiter' : (step === 2 ? 'Reservieren' : 'Schliessen'))}
                </Button>
            </DialogActions>
            <Snackbar
                open={emailErrorOpen}
                autoHideDuration={8000}
                onClose={() => setEmailErrorOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="warning" onClose={() => setEmailErrorOpen(false)} sx={{ width: '100%' }}>
                    Die Bestätigungsmail konnte nicht gesendet werden. Deine Reservierung ist trotzdem gespeichert.
                    Bitte kontaktiere uns unter <strong>info@moonnila.ch</strong> für eine manuelle Bestätigung.
                </Alert>
            </Snackbar>
            <Snackbar
                open={appointmentAlreadyBookedOpen}
                autoHideDuration={8000}
                onClose={() => setAppointmentAlreadyBookedOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="info" onClose={() => setAppointmentAlreadyBookedOpen(false)} sx={{ width: '100%' }}>
                    Dieser Termin wurde in der Zwischenzeit bereits gebucht. Bitte wählen Sie einen anderen.
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

export default NailDetailDialog;
