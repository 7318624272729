import React from 'react';
import beauty from './../../../assets/img/Beauty.png';
import nails from './../../../assets/img/Nails.png';
import './ImageCards.css';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ImageCards = () => {
    return (
        <div className='image-container'>
            <Link to='/beauty' className='image-card'>
                <div className="card-image-wrapper">
                    <LazyLoadImage
                        src={beauty}
                        alt="Moonnila Beauty"
                        className='card-image'
                        effect="blur"
                    />
                </div>
                <div className='card-overlay'>
                    <span className='card-text'>BEAUTY</span>
                </div>
            </Link>
            <Link to='/nails' className='image-card'>
                <div className="card-image-wrapper">
                    <LazyLoadImage
                        src={nails}
                        alt="Moonnila Nails"
                        className='card-image'
                        effect="blur"
                    />
                </div>
                <div className='card-overlay'>
                    <span className='card-text'>NAILS</span>
                </div>
            </Link>
        </div>
    );
}

export default ImageCards;
