import React, { useRef, useEffect, useState } from 'react';
import './Beauty.css';
import BridalImage from './../../assets/img/beauty/Bridal.jpg';
import MehendiImage from './../../assets/img/beauty/Mehendi.jpg';
import PreeShootImage from './../../assets/img/beauty/PreShoot.jpg';
import BridesmaidsImage from './../../assets/img/beauty/Bridemaids.jpg';
import BridalImageLow from './../../assets/img/beauty/Bridal_Low.jpg';
import MehendiImageLow from './../../assets/img/beauty/Mehendi_Low.jpg';
import PreeShootImageLow from './../../assets/img/beauty/PreShoot_Low.jpg';
import BridesmaidsImageLow from './../../assets/img/beauty/Bridemaids_Low.jpg';
import BeautyTextSection from '../../components/beauty/textSection/BeautyTextSection';
import BeautyImageSection from '../../components/beauty/imageSection/BeautyImageSection';
import RequestDialog from '../../components/beauty/requestDialog/RequestDialog';

function Beauty() {
    const sections = {
        bridal: useRef(null),
        reception: useRef(null),
        mehendi: useRef(null),
        civil: useRef(null),
        preshoot: useRef(null),
        guest: useRef(null),
        bridesmaids: useRef(null),
        birthday: useRef(null),
    };

    const [activeSection, setActiveSection] = useState('bridal');
    const [selectedService, setSelectedService] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    const scrollToSection = (ref) => {
        const offset = 6 * 16;
        const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
        const scrollPosition = elementPosition - offset;
    
        window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
        });
    };

    const handleOpenDialog = (service) => {
        setSelectedService(service);
        setDialogOpen(true);
    }

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.getAttribute('data-section'));
                }
            });
        }, observerOptions);

        Object.values(sections).forEach((section) => {
            if (section.current) observer.observe(section.current);
        });

        return () => observer.disconnect();
    // eslint-disable-next-line
    }, []);

    return (
        <div className="beauty-section">
            <RequestDialog open={dialogOpen} onClose={() => setDialogOpen(false)} initialService={selectedService} />
            <nav className="beauty-nav">
                {Object.keys(sections).map((key, index, arr) => (
                    <React.Fragment key={key}>
                        <button
                            onClick={() => scrollToSection(sections[key])}
                            className={activeSection === key ? 'active' : ''}
                        >
                            {key.charAt(0).toUpperCase() + key.slice(1)}
                        </button>
                        {index < arr.length - 1 && <span>|</span>}
                    </React.Fragment>
                ))}
            </nav>
            <hr />
            <div ref={sections.bridal} data-section="bridal">
                <BeautyImageSection
                    title="Bridal"
                    text="Jede Braut ist einzigartig – und genau das fange ich ein. Gemeinsam entwickeln wir dein perfektes Make-up und Styling, das deine persönliche Vision widerspiegelt. Mit viel Liebe zum Detail und durch gezielte Vorbereitung sorge ich dafür, dass du dich an deinem grossen Tag strahlend und selbstbewusst fühlst. Dein Look bleibt den ganzen Tag über makellos – von den ersten Ritualen bis zum finalen Abschied."
                    imageSrc={BridalImage}
                    placeholderSrc={BridalImageLow}
                    isImageLeft={true}
                    onRequest={() => handleOpenDialog('Bridal')}
                />
            </div>
            <hr />
            <div ref={sections.reception} data-section="reception">
                <BeautyTextSection 
                    title="Reception" 
                    text="Bei der Hochzeitsfeier geht es darum, dein Strahlen als frisch Verheiratete perfekt in Szene zu setzen. Dein Stil und deine Persönlichkeit stehen dabei im Mittelpunkt – ich kreiere ein Make-up und Styling, das dich den ganzen Abend über glamourös aussehen lässt und auf jedem Foto glänzen lässt."
                    onRequest={() => handleOpenDialog('Reception')}
                />
            </div>
            <hr />
            <div ref={sections.mehendi} data-section="mehendi">
                <BeautyImageSection
                    title="Mehendi"
                    text="Die Mehendi-Zeremonie ist eine bunte und lebhafte Feier der Traditionen. Ich schaffe einen Look, der nicht nur deine Kultur, sondern auch deine Individualität zum Ausdruck bringt. Mein Ziel ist es, dass du die ganze Nacht über strahlst und dich in deinem Make-up und Styling absolut wohl fühlst."
                    imageSrc={MehendiImage}
                    placeholderSrc={MehendiImageLow}
                    isImageLeft={false}
                    onRequest={() => handleOpenDialog('Mehendi')}
                />
            </div>
            <hr />
            <div ref={sections.civil} data-section="civil">
                <BeautyTextSection 
                    title="Civil Wedding" 
                    text="Für deine standesamtliche Hochzeit gestalte ich ein Make-up, das Eleganz und zeitlose Schönheit vereint. Ich lege Wert darauf, deinen Stil zu verstehen und einen Look zu kreieren, der deine natürliche Ausstrahlung perfekt unterstreicht und dich den ganzen Tag über strahlend begleit"
                    onRequest={() => handleOpenDialog('Civil')}
                />
            </div>
            <hr />
            <div ref={sections.preshoot} data-section="preshoot">
                <BeautyImageSection
                    title="Pre-Shoot"
                    text="Dein Pre-Shooting fängt die Vorfreude auf den grossen Tag ein. Gemeinsam entwickeln wir ein Styling, das dich vor der Kamera und auf jedem Foto einzigartig strahlen lässt. Mein Make-up und Styling bringen deine Persönlichkeit zur Geltung, sodass du dich selbstbewusst und wunderschön vor der Kamera präsentierst."
                    imageSrc={PreeShootImage}
                    placeholderSrc={PreeShootImageLow}
                    isImageLeft={true}
                    onRequest={() => handleOpenDialog('Preshoot')}
                />
            </div>
            <hr />
            <div ref={sections.guest} data-section="guest">
                <BeautyTextSection 
                    title="Guests" 
                    text="Als Gast möchtest du an diesem besonderen Tag stilvoll und elegant auftreten, ohne dabei die Braut zu überstrahlen. Ich kreiere ein Make-up und Styling, das deine natürliche Schönheit unterstreicht und perfekt zum Anlass passt. Egal ob glamourös, dezent oder modern – dein Look bleibt den ganzen Tag über frisch und makellos. So kannst du die Feier in vollen Zügen geniessen und auf jedem Foto strahlen."
                    onRequest={() => handleOpenDialog('Guest')}
                />
            </div>
            <hr />
            <div ref={sections.bridesmaids} data-section="bridesmaids">
                <BeautyImageSection
                    title="Bridesmaids"
                    text="Deine Bridesmaids sind ein wichtiger Teil deines grossen Tages, und ich sorge dafür, dass sie sich besonders und geschätzt fühlen. Gemeinsam kreieren wir Looks, die ihre Persönlichkeit unterstreichen und deine Hochzeitsästhetik perfekt ergänzen. Mein Ziel ist es, dass sie sich den ganzen Tag über selbstbewusst und wunderschön fühlen."
                    imageSrc={BridesmaidsImage}
                    placeholderSrc={BridesmaidsImageLow}
                    isImageLeft={false}
                    onRequest={() => handleOpenDialog('Bridesmaids')}
                />
            </div>
            <hr />
            <div ref={sections.birthday} data-section="birthday" style={{marginBottom: "1rem"}}>
                <BeautyTextSection 
                    title="Birthday" 
                    text="Dein Geburtstag ist dein Tag, um zu glänzen! Ich sorge dafür, dass du bei jeder Feier und auf jedem Foto im Mittelpunkt stehst. Mit einem Make-up und Styling, das deinen Stil perfekt betont, wirst du den ganzen Tag und die ganze Nacht über strahlen – für unvergessliche Momente und Erinnerungen."
                    onRequest={() => handleOpenDialog('Birthday')}
                />
            </div>
        </div>
    );
}

export default Beauty;
