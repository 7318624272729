
import { useCallback } from 'react';
import './GoogleMaps.css';
import {APIProvider, AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps';

function GoogleMaps() {
  const apiKey = 'AIzaSyAqOA7pWB8B2By1cB9z0EE9RjZEZt1iwsY';
  const mapId = '31494908322c8966';
  const center = { lat: 47.23884, lng: 7.78363 };
  const address = 'Mumenthalstrasse 35, 4912 Aarwangen, Schweiz';

  const handleClick = useCallback(() => {
    const mapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
    window.open(mapsUrl, '_blank');
  }, []);

  return (
    <div className='map-wrapper'>
        <div className="map-container">
            <APIProvider apiKey={apiKey}>
                <Map
                    defaultZoom={13}
                    defaultCenter={ center }
                    mapId={mapId}
                >
                    <AdvancedMarker
                        key="Moonnila"
                        clickable={true}
                        onClick={handleClick}
                        position={ center }>
                            <Pin background={'#BE9F93'} glyphColor={'#FBF3EB'} borderColor={'#BE9F93'} />
                    </AdvancedMarker>
                </Map>
            </APIProvider>
        </div>
    </div>
  ); 
}

export default GoogleMaps;