import NailCard from "../../components/nails/nailCard/NailCard";
import PlainColorImage from "../../assets/img/nails/PlainColorImg.jpeg";
import MultipleColorsImage from "../../assets/img/nails/MultipleColorImg.jpg";
import BasicCustomImage from "../../assets/img/nails/BasicCustomImg.jpg";
import "./Nails.css";
import GoogleMaps from "../../components/nails/googleMap/GoogleMaps";
import { useState } from "react";
import { Grid } from "@mui/material";
import NailDetailDialog from "./NailDetailDialog";

const nailData = [
    {
        title: 'PLAIN COLOUR',
        description: 'Dieses Set enthält nur eine Farbe.',
        price: '45',
        image: PlainColorImage,
    },
    {
        title: 'MULTIPLE COLOURS',
        description: 'Dieses Set besteht aus zwei unterschiedlichen Farben.',
        price: '55',
        image: MultipleColorsImage,
    },
    {
        title: 'BASIC CUSTOM DESIGN',
        description: 'Dieses Set ist eine Kombination aus zwei Farben, die miteinander vermischt werden.',
        price: '60',
        image: BasicCustomImage,
    },
];

function Nails() {
    const [selectedNail, setSelectedNail] = useState(null);

    const handleCardClick = (nail) => {
        setSelectedNail(nail);
    };

    const handleCloseDialog = () => {
        setSelectedNail(null);
    };
    
    return (
        <div className="nails">
            <div className="nail-card-gallery">
                {nailData.map((nail) => (
                    <Grid item key={nail.title} xs={12} sm={6} md={4}>
                        <NailCard
                            title={nail.title}
                            description={nail.description}
                            price={nail.price}
                            image={nail.image}
                            onClick={() => handleCardClick(nail)}
                        />
                    </Grid>
                ))}
            </div>
            {selectedNail && (
                <NailDetailDialog
                    open={Boolean(selectedNail)}
                    onClose={handleCloseDialog}
                    title={selectedNail.title}
                    description={selectedNail.description}
                    price={selectedNail.price}
                    image={selectedNail.image}
                />
            )}
            <GoogleMaps />
        </div>
    )
}

export default Nails;