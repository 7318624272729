import React, { useState } from 'react';
import './BeautyImageSection.css';
import { Button } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactModal from 'react-modal';
ReactModal.setAppElement('#root');

const BeautyImageSection = ({ title, text, imageSrc, placeholderSrc, isImageLeft, onRequest }) => {
    const containerClass = isImageLeft ? 'image-section image-left' : 'image-section image-right';
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={containerClass}>
            <div className="content-section">
                <h2>{title}</h2>
                <p>{text}</p>
                <Button onClick={onRequest} color="primary" variant="contained" sx={{fontSize: {
                    xs: '0.9rem',
                    sm: '1rem',
                    md: '1.2rem',
                },}}>
                    Anfragen
                </Button>
            </div>
            <div className="image-section__image">
                <LazyLoadImage
                    src={placeholderSrc}
                    alt={title}
                    effect="blur"
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() => setIsOpen(true)}
                />
                <ReactModal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    style={{
                        content: {
                            background: 'transparent',
                            border: 'none',
                            inset: '10%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            zIndex: 1000,
                        },
                    }}
                    >
                    <img
                        src={imageSrc}
                        alt={title}
                        style={{
                            maxWidth: '90vw',
                            maxHeight: '90vh',
                            objectFit: 'contain',
                            borderRadius: '8px',
                            zIndex: 1999,
                        }}
                        onClick={() => setIsOpen(false)}
                    />
                </ReactModal>
            </div>
        </div>
    );
};

export default BeautyImageSection;
