import { db } from "./firebaseConfig";
import { collection, doc, getDocs, limit, query, Timestamp, updateDoc, where, getDoc } from "firebase/firestore";

const getAppointments = async () => {
    const appointmentsCollection = collection(db, 'appointments');

    const now = new Date();

    const appointmentsQuery = query(
        appointmentsCollection,
        where('status', '==', 'free'),
        where('date', '>', now),
        limit(8),
    );

    try {
        const querySnapshot = await getDocs(appointmentsQuery);
        const appointments = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                ...data,
                date: data.date?.toDate ? data.date.toDate() : data.date,
            };
        });

        return appointments;
    } catch (error) {
        console.error('Error retrieving appointments: ', error);
        throw error;
    }
};

const getAppointmentById = async (id) => {
    try {
        const appointmentRef = doc(db, 'appointments', id);
        const appointmentSnap = await getDoc(appointmentRef);

        if (!appointmentSnap.exists()) {
            throw new Error(`Appointment with ID ${id} does not exist.`);
        }

        const data = appointmentSnap.data();
        return {
            id: appointmentSnap.id,
            ...data,
            date: data.date?.toDate ? data.date.toDate() : data.date,
        };
    } catch (error) {
        console.error('Error fetching appointment by ID:', error);
        throw error;
    }
};

const updateAppointment = async (appointmentId, updatedData) => {
    try {
        const appointmentRef = doc(db, 'appointments', appointmentId);
        updatedData.date = Timestamp.fromDate(updatedData.date.toDate());
        await updateDoc(appointmentRef, updatedData);
    } catch (error) {
        console.error('Error updating appointment: ', error);
        throw error;
    }
};

export const NailService = {
    getAppointments,
    updateAppointment,
    getAppointmentById
};