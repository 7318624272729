import React from "react";
import "./Agb.css";

function Agb() {
    return (
        <div className="imprint-container">
            <h1 className="imprint-heading">Allgemeine Geschäftsbedingungen (AGB) für Moonnila Dienstleistungen</h1>
            <div className="imprint-content">
                <div className="imprint-section">
                    <strong>1. Vertragsgegenstand</strong>
                    <span>Diese Allgemeinen Geschäftsbedingungen (AGB) regeln die Geschäftsbeziehung zwischen Moonnilabeauty und dem Kunden im Bereich der Moonnila Dienstleistungen.</span>
                </div>
                <div className="imprint-section">
                    <strong>2. Terminvereinbarung</strong>
                    <span>• Termine werden nach vorheriger Absprache und Verfügbarkeit vergeben.</span>
                    <span>• Eine Terminbestätigung erfolgt schriftlich oder elektronisch.</span>
                </div>
                <div className="imprint-section">
                    <strong>3. Leistungsumfang</strong>
                    <span>• Der Make-up Artist erbringt die vereinbarten Dienstleistungen am vereinbarten Ort und zur vereinbarten Zeit.</span>
                    <span>• Änderungen oder zusätzliche Leistungen müssen rechtzeitig besprochen und schriftlich festgehalten werden.</span>
                </div>
                <div className="imprint-section">
                    <strong>4. Preise und Zahlungsbedingungen</strong>
                    <span>• Die Preise für die Dienstleistungen werden im Voraus vereinbart und sind in der Regel auf der Website oder in einer Preisliste angegeben.</span>
                    <span>• Zahlungen sind als Vorauszahlung fällig und müssen vor Erbringung der Dienstleistung beglichen werden.</span>
                    <span>• Zur Bestätigung der Buchung ist eine Anzahlung von 50% des Gesamtrechnungsbetrags erforderlich.</span>
                    <span>• Akzeptierte Zahlungsmethoden: Überweisung / TWINT.</span>
                </div>
                <div className="imprint-section">
                    <strong>5. Stornierung und Terminverschiebung</strong>
                    <span>• Der Kunde kann einen Termin bis zu zwei Wochen vor dem vereinbarten Termin kostenfrei stornieren oder verschieben.</span>
                    <span>• Bei einer Stornierung oder Terminverschiebung innerhalb zwei Wochen vor dem Termin wird die Anzahlung nicht zurückerstattet.</span>
                    <span>• Bei Nichterscheinen oder einer Stornierung am Tag des Termins wird der volle Preis berechnet.</span>
                </div>
                <div className="imprint-section">
                    <strong>6. Haftung</strong>
                    <span>• Der Make-up Artist haftet nur für Schäden, die auf vorsätzlicher oder grob fahrlässiger Pflichtverletzung beruhen.</span>
                    <span>• Der Kunde ist verpflichtet, den Make-up Artist über etwaige Allergien oder Hautprobleme zu informieren. Der Make-up Artist haftet nicht für Schäden, die durch unvollständige oder falsche Angaben des Kunden entstehen.</span>
                </div>
                <div className="imprint-section">
                    <strong>7. Datenschutz</strong>
                    <span>• Personenbezogene Daten des Kunden werden ausschließlich zum Zweck der Terminabwicklung und zur Erbringung der Dienstleistungen verwendet.</span>
                    <span>• Eine Weitergabe der Daten an Dritte erfolgt nur, soweit dies zur Vertragserfüllung notwendig ist oder der Kunde ausdrücklich zugestimmt hat.</span>
                </div>
                <div className="imprint-section">
                    <strong>8. Schlussbestimmungen</strong>
                    <span>• Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.</span>
                    <span>• Änderungen oder Ergänzungen dieser AGB bedürfen der Schriftform.</span>
                    <span>• Es gilt das Recht der Schweizer Gesetz.</span>
                </div>
                <div className="imprint-section">
                    <strong>Moonnilabeauty</strong>
                    <span>Ajitha Thirukumar</span>
                    <span>E-Mail: <a href="mailto:info@moonnila.ch">info@moonnila.ch</a></span>
                    <span>Adresse: Mumenthalstrasse 35, 4912 Aarwangen</span>
                    <span>Telefon: 076 480 62 30</span>
                </div>
            </div>
        </div>
    );
}

export default Agb;
