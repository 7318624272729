import React from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress,
    Box
} from '@mui/material';
import Grid2 from '@mui/material/Grid2';

function NailAppointmentSelector({ appointments, selectedAppointment, onDateChange, loading, isMobile }) {

    return (
        <Grid2 xs={12} md={6} sx={{ 
            textAlign: isMobile ? 'center' : 'left', marginTop: isMobile ? '1rem' : 0, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <FormControl
                component="fieldset"
                sx={{
                    display: 'flex',
                    justifyContent: 'top',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    textAlign: isMobile ? 'center' : 'left',
                }}
            >
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <RadioGroup
                        value={selectedAppointment ? selectedAppointment.date.format('YYYY-MM-DDTHH:mm') : ''}
                        onChange={(event) => {
                            const appointment = appointments.find(appt => appt.date.format('YYYY-MM-DDTHH:mm') === event.target.value);
                            onDateChange(appointment);
                        }}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                            gap: '0.5rem',
                            overflowY: 'auto',
                            paddingRight: '0.5rem'
                        }}
                        >
                        {appointments.map((appointment) => (
                            <FormControlLabel
                            key={appointment.id}
                            value={appointment.date.format('YYYY-MM-DDTHH:mm')}
                            control={<Radio />}
                            label={appointment.date.format('DD.MM.YYYY, HH:mm [Uhr]')}
                            sx={{
                                backgroundColor: '#f7f7f7',
                                borderRadius: '8px',
                                padding: '0.5rem 1rem',
                                margin: 0
                            }}
                            />
                        ))}
                    </RadioGroup>
                )}
            </FormControl>
        </Grid2>
    );
}

export default NailAppointmentSelector;